const formules = () => {
    // Table
    let formules = document.querySelector(".formules table");
    if (formules) {
        const formulesTl = gsap.timeline({
            scrollTrigger: {
                trigger: formules,
                start: "0 70%",
            },
        });

        formulesTl
            .from(
                ".formules",
                {
                    opacity: 0,
                    y: 40,
                    duration: 1.5,
                    ease: "power1.out",
                },
                "+=1.5"
            )
            .from(
                ".formules table thead",
                {
                    y: 40,
                    opacity: 0,
                    duration: 1,
                    ease: "power3.out",
                },
                "<"
            )
            .from(".formules tbody tr", {
                y: 40,
                opacity: 0,
                duration: 1,
                stagger: 0.2,
                ease: "power3.out",
            });
    }

    // Services inclus
    let includedServices = document.querySelector(".services__included");
    if (includedServices) {
        const includedServicesTl = gsap.timeline({
            defaults: {
                stagger: 0.1,
                duration: 0.5,
                ease: "power2.out",
            },
            scrollTrigger: {
                trigger: includedServices,
                start: "0 70%",
            },
        });

        includedServicesTl
            .from(".services__included--title p", {
                y: 40,
                opacity: 0,
            })
            .from(".services__included--item svg", {
                scale: 0,
            })
            .from(
                ".services__included--item dt span",
                {
                    y: 50,
                },
                "<.2"
            )
            .from(
                ".services__included--item dd",
                {
                    y: 40,
                    opacity: 0,
                },
                "<.2"
            );
    }

    // Services additionnels
    let serviceAdditional = document.querySelector(".services__additional");
    if (serviceAdditional) {
        let serviceAdditionalTl = gsap.timeline({
            scrollTrigger: {
                trigger: serviceAdditional,
                start: "0 70%",
            },
        });

        serviceAdditionalTl
            .from(".service__additional--icon div", {
                y: 50,
                opacity: 0.5,
                duration: 0.5,
                stagger: 0.2,
            })
            .from(
                ".service__additional--title div",
                {
                    y: 25,
                    duration: 0.75,
                    ease: "power1.out",
                    stagger: 0.2,
                },
                "<"
            )
            .from(
                ".service__additional--content",
                {
                    y: 25,
                    opacity: 0,
                    duration: 1,
                    ease: "power1.out",
                    stagger: 0.2,
                },
                "<"
            );
    }
};

export default formules;
