const serviceTechnique = () => {
    // Expertises
    let expertises = document.querySelector(".expertises");
    if (expertises) {
        gsap.utils.toArray(".expertise").forEach((expertise) => {
            let expertisesTl = gsap.timeline({
                scrollTrigger: {
                    trigger: expertise,
                    start: "0 70%",
                },
            });

            expertisesTl
                .from(expertise, {
                    y: 40,
                    opacity: 0,
                    duration: 1,
                })
                .from(
                    expertise.querySelector("h3 span"),
                    {
                        y: 72,
                        duration: 1,
                        ease: "power2.out",
                    },
                    "<.2"
                )
                .from(
                    expertise.querySelector("h3 + p"),
                    {
                        y: 40,
                        opacity: 0,
                        duration: 1,
                        ease: "power2.out",
                    },
                    "<.4"
                )
                .from(
                    expertise.querySelectorAll(".expertise--icon"),
                    {
                        y: 48,
                        duration: 1,
                        ease: "power2.out",
                        stagger: 0.3,
                    },
                    "<.4"
                )
                .from(
                    expertise.querySelectorAll(".expertise dt span"),
                    {
                        y: 50,
                        duration: 1,
                        ease: "power2.out",
                        stagger: 0.3,
                    },
                    "<"
                )
                .from(
                    expertise.querySelectorAll(".expertise dd"),
                    {
                        y: 50,
                        opacity: 0,
                        duration: 1,
                        ease: "power2.out",
                        stagger: 0.3,
                    },
                    "<"
                )
                .to(
                    expertise.querySelectorAll(".expertise__layer--first"),
                    {
                        width: "100%",
                        duration: 0.5,
                        ease: "power2.out",
                    },
                    "<"
                )
                .to(
                    expertise.querySelectorAll(".expertise--image"),
                    {
                        opacity: 1,
                    },
                    ">"
                )
                .to(
                    expertise.querySelectorAll(".expertise__layer--last"),
                    {
                        width: "100%",
                        duration: 0.7,
                        ease: "power1.out",
                    },
                    "<"
                )
                .to(
                    expertise.querySelectorAll(
                        ".expertise__layer--first, .expertise__layer--last"
                    ),
                    {
                        left: "100%",
                        duration: 0.5,
                        ease: "power2.out",
                    },
                    ">"
                );
        });
    }
};

export default serviceTechnique;
