const quiSommeNous = () => {
    // Réassurance
    let reassurance = document.querySelector(".reassurance");
    if (reassurance) {
        const reassuranceTl = gsap.timeline({
            scrollTrigger: {
                trigger: reassurance,
                start: "0 60%",
            },
        });

        reassuranceTl
            .from(".reassurance__media", {
                opacity: 0,
                scale: 0.9,
                ease: "power1.out",
                duration: 1,
            })
            .from(
                ".reassurance__content--icon div",
                {
                    y: 50,
                    ease: "power1.out",
                    duration: 0.6,
                },
                "<.2"
            )
            .from(
                ".reassurance__content h2, .reassurance__content p",
                {
                    y: 20,
                    opacity: 0,
                    ease: "power2.out",
                    duration: 1,
                    stagger: 0.2,
                },
                "<.2"
            )
            .from(
                ".reassurance__content--milestone span",
                {
                    y: 30,
                    ease: "power2.out",
                    duration: 0.6,
                    stagger: 0.15,
                },
                "<.5"
            )
            .from(
                ".reassurance__content--milestone dt",
                {
                    y: 30,
                    opacity: 0,
                    ease: "power2.out",
                    duration: 0.6,
                    stagger: 0.15,
                },
                "<.2"
            );
    }

    // Team
    let team = document.querySelector(".team");
    if (team) {
        const teamTl = gsap.timeline({
            defaults: {
                stagger: 0.1,
                duration: 0.6,
            },
            scrollTrigger: {
                trigger: team,
                start: "0 60%",
            },
        });

        teamTl
            .from(".team__employee--image img", {
                opacity: 0,
                scale: 1.2,
            })
            .from(
                ".team__employee--job h3 span",
                {
                    y: 40,
                    ease: "power1.out",
                },
                "<"
            )
            .from(
                ".team__employee--job-layer",
                {
                    width: "100%",
                    ease: "power1.out",
                },
                "<"
            )
            .to(
                ".team__employee--image img, .team__employee--job-layer + span",
                {
                    opacity: 1,
                },
                "<.5"
            )
            .to(".team__employee--job-layer", {
                left: "100%",
                duration: 0.75,
                ease: "power1.out",
            })
            .from(
                ".team__employee--jobContent",
                {
                    y: 20,
                    opacity: 0,
                    duration: 1,
                    stagger: 0.2,
                    ease: "power1.out",
                },
                "-=1.5"
            );
    }

    // Quote
    let quote = document.querySelector(".quote");
    if (quote) {
        const quoteTl = gsap.timeline({
            defaults: {},
            scrollTrigger: {
                trigger: quote,
                start: "0 70%",
            },
        });

        quoteTl
            .from(".quote h2 span", {
                y: 60,
                ease: "power1.out",
            })
            .from(
                ".quote__image",
                {
                    scale: 0.9,
                    opacity: 0,
                    duration: 1,
                    ease: "power1.out",
                },
                "<"
            )
            .from(
                ".quote__content > *",
                {
                    y: 20,
                    opacity: 0,
                    duration: 0.5,
                    stagger: 0.15,
                    ease: "power1.out",
                },
                "<"
            );
    }
};

export default quiSommeNous;
