const agences = () => {
    let agences = document.querySelector(".agencies");

    if (agences) {
        const agencesTl = gsap.timeline({
            defaults: {
                duration: 1,
            },
            scrollTrigger: {
                trigger: agences
            },
        });

        agencesTl
            .from(
                ('.agencies h2'),
                {
                    y: 60,
                    ease: 'power2.out'
                }
            )
            .from(
                ('.agencies__container .agencies__map'),
                {
                    opacity: 0,
                    duration: .5
                },
                '<.1'
            )
            .from(
                ('.agencies__container .agencies__map'),
                {
                    scale: .9,
                    duration: .5,
                    ease: 'power2.out'
                },
                '<'
            )
    }
};

export default agences;
