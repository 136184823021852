const agences = () => {
    let realisationHero = document.querySelector(".realisations__hero");
    if (realisationHero) {
        gsap.from(realisationHero.querySelectorAll("h1"), {
            ease: "power1.out",
            y: 40,
            opacity: 0,
            delay: 0.5,
        });
        gsap.from(realisationHero.querySelector("p"), {
            ease: "power1.out",
            y: 40,
            opacity: 0,
            delay: 0.7,
        });
    }

    let realisations = document.querySelector(".realisations");
    if (realisations) {
        const realisationsTl = gsap.timeline({
            ease: "power1.out",
            trigger: realisations,
            start: "0 70%",
        });

        realisationsTl.from(realisations.querySelectorAll(".realisation"), {
            y: 30,
            opacity: 0,
            stagger: 0.15,
        });
    }
};

export default agences;
