const temoignages = () => {
    let quote = document.querySelector(".quote");

    if (quote) {
        const quoteTl = gsap.timeline({
            scrollTrigger: {
                trigger: quote,
                start: "0 70%",
            },
        });

        quoteTl
            .from(".quote__logo", {
                opacity: 0,
                duration: 1,
                ease: "power1.out",
            })
            .from(
                ".quote__content",
                {
                    y: 30,
                    opacity: 0,
                    duration: 1,
                    ease: "power1.out",
                },
                "<.5"
            )
            .from(
                ".quote__infos > *",
                {
                    opacity: 0,
                    duration: 1,
                    stagger: 0.2,
                    ease: "power1.out",
                },
                "<.5"
            );
    }
};

export default temoignages;
