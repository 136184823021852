const serviceBasique = () => {
    // Content
    let content = document.querySelector(".content");

    if (content) {
        const contentTl = gsap.timeline({
            scrollTrigger: {
                trigger: content,
                start: "0 70%",
            },
        });

        contentTl
            .from(
                ".content--image",
                {
                    opacity: 0,
                    duration: 2.3,
                },
                0
            )
            .from(
                ".content h3, .content--text > *",
                {
                    y: 20,
                    opacity: 0,
                    duration: 0.5,
                    stagger: 0.15,
                },
                "<"
            );
    }
};

export default serviceBasique;
