const accreditations = () => {
    // Logos
    let logos = document.querySelector(".logos");
    if (logos) {
        const logosTl = gsap.timeline({
            scrollTrigger: {
                trigger: logos,
                start: "0 70%",
            },
        });

        logosTl.from(".logo", {
            opacity: 0,
            scale: 0.9,
            ease: "power1.out",
            stagger: 0.15,
        });
    }

    // Content top
    let topContent = document.querySelector(".topContent");
    if (topContent) {
        const topContentTl = gsap.timeline({
            scrollTrigger: {
                trigger: topContent,
                start: "0 60%",
            },
        });

        topContentTl
            .from(".topContent__image img", {
                left: -40,
                opacity: 0,
                ease: "power1.out",
                duration: 1.5,
            })
            .from(
                ".topContent__content > h3",
                {
                    y: 20,
                    opacity: 0,
                    ease: "power1.out",
                },
                "<.5"
            )
            .from(
                ".topContent__content div > *",
                {
                    y: 20,
                    opacity: 0,
                    ease: "power1.out",
                    stagger: 0.15,
                },
                "<"
            );
    }

    // Content middle
    let middleContent = document.querySelector(".middleContent");
    if (middleContent) {
        const middleContentTl = gsap.timeline({
            scrollTrigger: {
                trigger: middleContent,
                start: "0 40%",
            },
        });

        middleContentTl
            .from(".middleContent__image img", {
                scale: 0.9,
                opacity: 0,
                ease: "power1.out",
            })
            .from(
                ".middleContent__content > h3",
                {
                    y: 20,
                    opacity: 0,
                    ease: "power1.out",
                },
                "<.5"
            )
            .from(
                ".middleContent__content div > *",
                {
                    y: 20,
                    opacity: 0,
                    ease: "power1.out",
                    stagger: 0.15,
                },
                "<"
            );
    }

    // Content middle
    let bottomContent = document.querySelector(".bottomContent");
    if (bottomContent) {
        const bottomContentTl = gsap.timeline({
            scrollTrigger: {
                trigger: bottomContent,
                start: "0 40%",
            },
        });

        bottomContentTl
            .from(
                ".bottomContent__title p",
                {
                    y: 20,
                    opacity: 0,
                    ease: "power1.out",
                },
                "<.5"
            )
            .from(
                ".bottomContent__content div > *",
                {
                    y: 20,
                    opacity: 0,
                    ease: "power1.out",
                    stagger: 0.15,
                },
                "<"
            );
    }
};

export default accreditations;
