import commonAnimations from './common/commonAnimations';
import frontPage from './pages/frontPage';
import formules from './pages/formules';
import serviceTechnique from './pages/serviceTechnique';
import serviceBasique from './pages/serviceBasique';
import agences from './pages/agences';
import quiSommeNous from './pages/quiSommesNous';
import temoignages from './pages/temoignages';
import accreditations from './pages/accreditations';
import blog from './pages/blog';
import realisations from './pages/realisations';

const animations = (data) => {

    // gsap.config({ nullTargetWarn: false });
    const bodyClasses = document.body.classList
    
    
    // Homepage
    if (bodyClasses.contains('home') || bodyClasses.contains('page-template-demo_home')) {
        frontPage();
        console.log('home');
    }


    // Formules
    if (bodyClasses.contains('page-template-formules') || bodyClasses.contains('seo-template-seo_service')) {
        formules();
    }


    // Service Technique
    if (bodyClasses.contains('page-template-service-technique') || bodyClasses.contains('page-template-debarras')) {
        serviceTechnique();
    }


    // Service Basique
    if (bodyClasses.contains('page-template-service-basique')) {
        serviceBasique();
    }


    // Agence
    if (bodyClasses.contains('page-template-societe-agences')) {
        agences();
    }


    // Qui sommes-nous
    if (bodyClasses.contains('page-template-societe-qui-sommes-nous')) {
        quiSommeNous();
    }


    // Témoignages
    if (bodyClasses.contains('page-template-reference-temoignages')) {
        temoignages();
    }


    // Accréditations
    if (bodyClasses.contains('page-template-reference-accreditations')) {
        accreditations();
    }

    // Réalisations
    if (bodyClasses.contains('page-template-realisations')) {
        realisations();
    }


    // Blog
    if (bodyClasses.contains('blog') || bodyClasses.contains('archive') || bodyClasses.contains('single-post')) {
        blog();
    }


    // Common
    commonAnimations(data);

}

export default animations;