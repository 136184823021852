import barba from "@barba/core";
import animations from "../animations/animations";

gsap.registerPlugin(ScrollToPlugin);

function playVideo(data) {
    const video = data.next.container.querySelector("video.hero__video");
    if (video) {
        console.log(video);
        video.play();
    } 
}

const delay = (n) => {
    n = n || 2000;
    return new Promise((done) => {
        setTimeout(() => {
            done();
        }, n);
    });
};

const leaveAnimation = async () => {
    var tl = gsap.timeline();
    tl.to("#transition", {
        opacity: 1,
        visibility: "visible",
        translateY: "100vh",
        ease: "power1.inOut",
        duration: 1.5,
    });

    tl.to("#transition", {
        opacity: 0,
        visibility: "hidden",
    });

    tl.set("#transition", { translateY: "-170vh" });
    tl.set("#transition", { opacity: 1 });
};

const transition = () => {
    barba.init({
        sync: false,
        debug: true,
        transitions: [
            {
                once(data) {
                    document.body.classList.remove("invisible");
                    animations(data);
                },

                async beforeOnce(data) {
                    document.body.classList.add("invisible");
                    leaveAnimation();
                    await delay(500);
                    document.body.classList.remove("invisible");
                },

                async leave(data) {
                    leaveAnimation();
                    console.log("leave");
                    await delay(750);
                    gsap.to(window, { scrollTo: 0 }, "-=2");
                },

                enter(data) {
                    animations(data);
                },
            },
        ],
        views: [
            {
                namespace: "contact",
                beforeEnter(data) {
                    const js = data.next.container.querySelectorAll(
                        "script.reload-on-ajax"
                    );
                    
                    [].forEach.call(js, function(script) {
                        var createScript = document.createElement("script");
                        if (typeof script.src !== 'undefined' && script.src) {
                            createScript.src = script.src;
                            data.next.container.appendChild(createScript);
                        } else {
                            createScript.innerHTML = script.innerHTML;
                            data.next.container.appendChild(createScript);
                        }
                    })
                }
            },
            {
                namespace: 'societe-agences',
                beforeEnter(data) {
                    const js = data.next.container.querySelectorAll(
                        "script.reload-on-ajax"
                    );
                    
                    [].forEach.call(js, function(script) {
                        var createScript = document.createElement("script");
                        if (typeof script.src !== 'undefined' && script.src) {
                            console.log(script);
                            createScript.src = script.src;
                            data.next.container.appendChild(createScript);
                        } else {
                            console.log('2', script);
                            createScript.innerHTML = script.innerHTML;
                            data.next.container.appendChild(createScript);
                        }
                    })
                }
            },
            {
                namespace: 'societe-qui-sommes-nous',
                beforeEnter(data) {
                    playVideo(data);
                    console.log('play');
                }
            },
            {
                namespace: 'front-page',
                beforeEnter(data) {
                    playVideo(data);
                }
            }
        ],
    });
};

export default transition;
