import Glide from '@glidejs/glide'
import transition from './utils/routing';

window.addEventListener('DOMContentLoaded', (e) => {

    gsap.registerPlugin(ScrollToPlugin);

    // Apply TailWind classes to nav dropdown <a>
    function ddAddClasses() {
        let dd = document.querySelectorAll('.is_dropdown .links--container a');
        let dropdownClasses = ['-m-3', 'p-3', 'flex', 'items-start', 'rounded-lg', 'hover:bg-gray-50', 'transition', 'ease-in-out', 'duration-150', 'menu--link']
        let lis = document.querySelectorAll('ul#menu-header-menu > li > a div p');
        let liClasses = ['text-gray-500', 'text-base', 'font-medium', 'hover:text-gray-900', 'group', 'inline-flex', 'items-center']

        if (dd) {
            dd.forEach(item => {
                item.classList.add.apply(item.classList, dropdownClasses)
            });
        }

        if (lis) {
            lis.forEach(item => {
                item.classList.add.apply(item.classList, liClasses)
                item.classList.remove('text-gray-900')
            });
        }
    }
    ddAddClasses();



    // Add dropdown icon
    function addDropdownIcon() {
        let lis = document.querySelectorAll('ul#menu-header-menu > li.menu-item-has-children > a p');

        for (let i = 0; i < lis.length; i++) {
            lis[i].insertAdjacentHTML('beforeend', '<svg class="ml-1.5 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>');            
        }
    }
    addDropdownIcon()


    // Hide dropdown onclick
    function hideDropdown() {
        let dropdownsLinks = document.querySelectorAll('.is_dropdown ul li a');

        dropdownsLinks.forEach(item => {
            item.addEventListener('click', () => {
                let dropdown = item.parentElement.parentElement.parentElement.parentElement;
                dropdown.classList.toggle('hdn');
            });
        });
    }
    hideDropdown()



    // Dropdown display & hide animations
    function displayDropdown() {
        let links = document.querySelectorAll('.menu-item-has-children > a');
        
        function closeOpenItems() {
            let openMenus = document.querySelectorAll('.is_dropdown');
            openMenus.forEach(el => {
                el.classList.add('hdn');
            });

            window.addEventListener('click', () => {
                openMenus.forEach(el => {
                    el.classList.add('hdn');
                })
            });
        }

          links.forEach(link => {
            var dropdown = link.nextElementSibling;

            link.addEventListener('click', (e) => {
                var shouldOpen = dropdown.classList.contains('hdn');

                closeOpenItems();
                
                if (shouldOpen) {
                    dropdown.classList.remove('hdn');  
                }
                
                e.stopPropagation();
            });


        });
    }
    displayDropdown();



    // Center formulas svgs
    function centerFormulasIcons() {
        var icons = document.querySelectorAll('#formulas td svg');

        if (icons) {
            icons.forEach(icon => {
                icon.classList.add('mx-auto');
            });
        }
    }
    centerFormulasIcons()


    // Add classes to <a> in footer
    function footerAddClasses() {
        let links = document.querySelectorAll('footer ul li a');
        let classes = ['text-base', 'text-gray-500', 'hover:text-gray-900']

        links.forEach(link => {
            link.classList.add.apply(link.classList, classes)
        });
    }
    footerAddClasses();



    const slider = document.querySelector('.glide');
    if (slider) {
        let glide = new Glide(slider, {
            type: 'carousel',
            autoplay: 2000,
            perView: 5,
        })

        glide.mount()
    }
    


    // Generate accordions in burger menu
    let subMenus = document.querySelectorAll('#burger .sub-menu');
    if (subMenus) {
        let containers = document.querySelectorAll('#burger .menu-item-has-children > a div');
        let buttons = document.querySelectorAll('#burger .menu-item-has-children > a div p');
        let wrapper;
        let spans;
        let arr = [];

        for (let i = 0; i < subMenus.length; i++) {

            // Create accordion wrapper
            wrapper = document.createElement('div');
            wrapper.appendChild(subMenus[i]);
            wrapper.classList.add('hidden');
            wrapper.classList.add('opacity-0');
            arr.push(wrapper);

            // Create accordion chevron
            spans = document.createElement('span');
            spans.innerText = '+';
            spans.classList.add('chevron');
            
            // DOM manipulation to insert accordion
            containers[i].insertAdjacentElement('beforeend', wrapper);
            buttons[i].insertAdjacentElement('beforeend', spans);

            buttons[i].addEventListener('click', e => {
                arr[i].classList.toggle('hidden');
                setTimeout(() => {
                    arr[i].classList.toggle('opacity-100');
                }, 1);
            })
        }

    }


    // Play video on click
    function playVideoOnClick() {
        let button = document.querySelector('.play-video');

        if (button) {
            button.addEventListener('click', () => {
                let video = button.nextElementSibling.querySelector('video');
                console.log(video);
                video.play();
            });
        }
    }
    playVideoOnClick();




    // ************************************************ //
    // ************************************************ //
    // ****************** ANIMATIONS ****************** //
    // ************************************************ //
    // ************************************************ //


    if (!document.body.classList.contains('logged-in')) {
        transition();
    }


    // ************************************************ //
    // ************************************************ //
    // ******************** SCROLL ******************** //
    // ************************************************ //
    // ************************************************ //

    if (!document.body.classList.contains('page-template-contact')) {
        let header = document.querySelector('header.header')

        window.addEventListener('scroll', (e) => {
            window.scrollY > 99 ? header.classList.add('sticky', 'animate-sticky') : header.classList.remove('sticky', 'animate-sticky')
            window.scrollY > 99 ? header.classList.remove('relative') : header.classList.add('relative')
        })
    }

});
