const blog = () => {
    // Filters
    gsap.from(".filters-container .filter", {
        y: 20,
        opacity: 0,
        ease: "power1.out",
        stagger: 0.15,
    });

    // Single Hero
    let hero = document.querySelector(".heroSingle");
    if (hero) {
        const heroTl = gsap.timeline();
        heroTl
            .from(".hero__background", {
                opacity: 0,
                scale: 1.5,
                ease: "power1.out",
                duration: 1,
            })
            .from(".hero__content", {
                transform: "rotateY(20deg) rotateZ(-4deg)",
                opacity: 0,
                duration: 1,
            });
    }
};

export default blog;
